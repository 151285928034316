@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

.bg_hero{
  background-size: cover !important;
  background-position: center !important;
}

html{
  scroll-behavior: smooth
}

@layer base {
  button {
    @apply border py-2 px-2 rounded-full;
  }
}

@layer utilities {
  .btn-dark {
    @apply bg-black text-white;
  }
}


 .projects .swiper-button-next:after, .projects  .swiper-button-prev:after{
  font-size: 1.9rem;
  color: black;
}